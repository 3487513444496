<template>
  <div class="profile">
    <div class="title">
      <div class="lefttext">我的收益</div>
      <div class="dayrange" @click="showSelectClick">
        <div class="icon"><img src="@/assets/img/dayicon.png" alt=""></div>
        <div class="text">{{ dayrangeText ? dayrangeText : '请选择日期' }}</div>
      </div>
    </div>
    <div class="contlist" :class="showmore ? 'alllist' : ''" >
      <div class="item" v-for="(item, index) in pageData.income" :key="index">
        <div class="icon"><img :src="require('../../assets/img/' + item.icon + '.png')" alt=""></div>
        <div class="text">
          <div class="tit">{{ item.title }}</div>
          <div class="math">{{ item.val?item.val:"0" }}</div>
        </div>
      </div>
    </div>
    <div class="bottombtn">
      <span v-if="!showmore" @click="showMoreClick">查看更多<van-icon name="arrow-down" /></span>
      <span v-else @click="hideMoreClick">收起<van-icon name="arrow-up" /></span>
    </div>

    <van-popup v-model:show="selectPupShow" position="bottom" round>
      <van-picker :title="pageData.income_condition[1].title" :columns="pageData.income_condition[0].data" :columns-field-names="dataType"  :default-index="pickdef"
        @confirm="okClick" @cancel="Cancel" />
    </van-popup>

    <van-calendar v-model:show="dayPupShow" type="range" :min-date="new Date(2010, 1, 1)" :show-confirm="false" :allow-same-day="true" @confirm="onConfirm" />

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import API from "@/axios";
import { Toast } from "vant";
import { useRoute } from "vue-router";
const route = useRoute();

const pickdef = ref("")
const showmore = ref(false)
const rangeType = ref("")
const dayrange = ref("")
const dayrangeText = ref("")
const mb_id = ref("")
const md_id = ref("")

const pageData = ref({})

const selectPupShow = ref(false)
const dayPupShow = ref(false)

const dataType = ref({
  text: 'title',
  val: 'val',
})

function showSelectClick() {
  selectPupShow.value = true
}

function okClick(val,index) {
  selectPupShow.value = false
  pickdef.value = index
  if (val.val == "diy") {
    dayPupShow.value = true;
  }else{
    rangeType.value = val.val
    dayrangeText.value = val.title
    ini()
  }
}

function Cancel() {
  selectPupShow.value = false
}

const formatDate = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const onConfirm = (values) => {
  const [start, end] = values;
  dayPupShow.value = false;
  dayrange.value = `${formatDate(start)}~${formatDate(end)}`;
  dayrangeText.value = `${formatDate(start)}~${formatDate(end)}`;
  rangeType.value = "diy"
  ini()
};

function showMoreClick() {
  showmore.value = true
}

function hideMoreClick() {
  showmore.value = false
}

const onceget = ref(false)

function ini() {
  API({
    url: "/api/incomeSummary",
    method: "post",
    data: {
      mb_id: mb_id.value,
      md_id: md_id.value,
      date_type: rangeType.value,
      date_range: dayrange.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      pageData.value = res.data.data

      if (onceget.value) {
        let valtext = res.data.data.income_condition[0].data
        for (const item of valtext) {
          if (item.checked == 1) {
            dayrangeText.value = item.title
          }
        }
      }
 
      onceget.value = false
      
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

onMounted(() => {
  if(route.query.mb_id){
    mb_id.value = route.query.mb_id
  }

  if(route.query.md_id){
    md_id.value = route.query.md_id
  }

  onceget.value = true

  ini()
})

</script>

<style lang="less" scoped>
.profile {
  background: @font-white-color;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #d7d7d7;
  margin: 0 20px;
  margin-bottom: 20px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .lefttext {
      font-size: @subtitle-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-color;
      line-height: @subtitle-size;
    }

    .dayrange {
      display: flex;
      align-items: center;

      .icon {
        width: 36px;
        margin-right: 10px;

        img {
          width: 100%;
          display: block;
        }
      }

      .text {
        font-size: @font-size;
        line-height: @font-size;
        color: @font-secondary-color;
      }
    }
  }

  .contlist {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    overflow: hidden;
    height: 120px;

    .item {
      width: calc(50% - 5px);
      display: flex;
      align-items: center;
      box-sizing: border-box;
      background: #f5f5f5;
      margin-right: 5px;
      margin-bottom: 10px;
      padding: 20px;
      border-radius: 10px;

      .icon {
        width: 60px;
        margin-right: 10px;

        img {
          width: 100%;
          display: block;
        }
      }

      .text {
        flex: 1;
        min-width: 0;

        .tit {
          font-size: @font-size;
          line-height: @font-size;
          margin-bottom: 10px;
          color: @font-secondary-color;
        }

        .math {
          font-size: @subtitle-size;
          line-height: @subtitle-size;
          font-weight: 600;
        }
      }
    }

    .item:nth-of-type(2n) {
      margin-right: 0;
    }
  }

  .alllist {
    height: auto;
  }

  .noHeight{
    height: 0;
  }
}

.bottombtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #dfdfdf;
  padding: 20px 0;
  font-size: 26px;
  line-height: 26px;
}
</style>