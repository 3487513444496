<template>
  <div class="business">
    <div class="title">
      <div class="lefttext">商务概况</div>
      <div class="dayrange" @click="showSelectClick">
        <div class="icon"><img src="@/assets/img/dayicon.png" alt=""></div>
        <div class="text">{{ dayrangeText ? dayrangeText : '请选择日期' }}</div>
      </div>
    </div>
    <div class="contlist" :class="showmore ? 'alllist' : listshouw ? '':'alllist'">
      <div class="nodata" v-if="!listshouw" >暂无数据</div>
      <div class="item" v-for="(item, index) in pageData.list" :key="index">
        <div class="itemcont">
          <div class="subtitle">商务名称</div>
          <div class="text">{{ item.name }}</div>
        </div>
        <div class="itemcont">
          <div class="subtitle">订单单数</div>
          <div class="text">{{ item.order_cnt }}</div>
        </div>
        <div class="itemcont">
          <div class="subtitle">销售额</div>
          <div class="text">{{ item.sales_amount }}</div>
        </div>
        <div class="itemcont">
          <div class="subtitle">实际收益</div>
          <div class="text">{{ item.real_income }}</div>
        </div>
        <div class="itemcont">
          <div class="subtitle">签约收益</div>
          <div class="text">{{ item.sign_income }}</div>
        </div>
        <div class="itemcont">
          <div class="subtitle">运维收益</div>
          <div class="text">{{ item.maintain_income }}</div>
        </div>
        <div class="itemcont">
          <div class="subtitle">活动收益</div>
          <div class="text">{{ item.activity_income }}</div>
        </div>
      </div>
    </div>
    <div class="bottombtn" v-if="listshouw">
      <span v-if="!showmore" @click="showMoreClick">查看更多<van-icon name="arrow-down" /></span>
      <span v-else @click="hideMoreClick">收起<van-icon name="arrow-up" /></span>
    </div>

    <van-popup v-model:show="selectPupShow" position="bottom" round>
      <van-picker :title="pageData.income_condition[1].title" :columns="pageData.income_condition[0].data" :default-index="pickdef"
        :columns-field-names="dataType" @confirm="okClick" @cancel="Cancel" />
    </van-popup>

    <van-calendar v-model:show="dayPupShow" type="range" :min-date="new Date(2010, 1, 1)" :show-confirm="false" :allow-same-day="true"
      @confirm="onConfirm" />

  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import API from "@/axios";
import { Toast } from "vant";
import { useRoute } from "vue-router";
const route = useRoute();

const pickdef = ref("")
const showmore = ref(false)
const rangeType = ref("")
const dayrange = ref("")
const dayrangeText = ref("")
const mb_id = ref("")
const md_id = ref("")
const listshouw = ref(true)

const pageData = ref({})

const selectPupShow = ref(false)
const dayPupShow = ref(false)

const dataType = ref({
  text: 'title',
  val: 'val',
})

function showSelectClick() {
  selectPupShow.value = true
}

function okClick(val,index) {
  selectPupShow.value = false
  pickdef.value = index
  if (val.val == "diy") {
    dayPupShow.value = true;
  } else {
    rangeType.value = val.val
    dayrangeText.value = val.title
    ini()
  }
}

function Cancel() {
  selectPupShow.value = false
}

const formatDate = (date) =>
  `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const onConfirm = (values) => {
  const [start, end] = values;
  dayPupShow.value = false;
  dayrange.value = `${formatDate(start)}~${formatDate(end)}`;
  dayrangeText.value = `${formatDate(start)}~${formatDate(end)}`;
  rangeType.value = "diy"
  ini()
};

function showMoreClick() {
  showmore.value = true
}

function hideMoreClick() {
  showmore.value = false
}

const onceget = ref(false)

function ini() {
  API({
    url: "/api/incomeStaff",
    method: "post",
    data: {
      mb_id: mb_id.value,
      md_id: md_id.value,
      date_type: rangeType.value,
      date_range: dayrange.value,
    },
  }).then((res) => {
    if (res.data.status == 200) {
      pageData.value = res.data.data

      if(pageData.value.list.length == 0){
        listshouw.value = false
      }else{
        listshouw.value = true
      }

      if (onceget.value) {
        let valtext = res.data.data.income_condition[0].data
        for (const item of valtext) {
          if (item.checked == 1) {
            dayrangeText.value = item.title
          }
        }
      }
      onceget.value = false
      
    } else {
      Toast.fail(res.data.msg);
    }
  });
}

onMounted(() => {

  if(route.query.mb_id){
    mb_id.value = route.query.mb_id
  }

  if(route.query.md_id){
    md_id.value = route.query.md_id
  }

  onceget.value = true

  ini()
})

</script>

<style lang="less" scoped>
.business {
  background: @font-white-color;
  padding: 20px 20px 0 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #d7d7d7;
  margin: 0 20px;
  margin-bottom: 20px;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 20px;
    margin-bottom: 20px;

    .lefttext {
      font-size: @subtitle-size;
      font-weight: bolder;
      font-family: "Regular";
      color: @font-color;
      line-height: @subtitle-size;
    }

    .dayrange {
      display: flex;
      align-items: center;

      .icon {
        width: 36px;
        margin-right: 10px;

        img {
          width: 100%;
          display: block;
        }
      }

      .text {
        font-size: @font-size;
        line-height: @font-size;
        color: @font-secondary-color;
      }
    }
  }

  .contlist {
    overflow: hidden;
    height: 520px;
    .nodata{
      width: 100%;
      padding: 20px 0 30px 0;
      font-size: @font-size;
      line-height: @font-size;
      color: @font-secondary-color;
      text-align: center;
    }
    .item {
      box-sizing: border-box;
      margin-right: 5px;
      border-radius: 10px;
      border-bottom: 1px solid #dfdfdf;
      padding-bottom: 20px;
      margin-bottom: 20px;

      .itemcont {
        display: flex;
        margin-bottom: 10px;

        .subtitle {
          font-size: @subtitle-size;
          line-height: @subtitle-size;
          color: @font-color;
          margin-right: 20px;
        }

        .text {
          flex: 1;
          min-width: 0;
          text-align: right;
          font-size: @subtitle-size;
          line-height: @subtitle-size;
          color: @font-secondary-color;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .itemcont:last-of-type {
        margin-bottom: 0;
      }

    }

    .item:last-of-type{
      border: 0;
    }
  }

  .alllist {
    height: auto;
  }
  .noHeight{
    height: 0;
  }
}

.bottombtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #dfdfdf;
  padding: 20px 0;
  font-size: 26px;
  line-height: 26px;
}
</style>