<template>
    <div class="billingList">
        <div class="topimg"><img src="@/assets/img/toppic1.png" alt="" /></div>
        <!-- 内容部分 -->
        <div class="page_cont">
            <Profile/>
            <Business/>
            <Income/>
        </div>
        <!-- 内容部分 -->
    </div>
</template>

<script setup>
import { onMounted , ref} from 'vue';
import Profile from "@/components/OrderManage/profile"
import Business from "@/components/OrderManage/business"
import Income from "@/components/OrderManage/income"
import mitts from "@/bus";
import API from "@/axios";
import {Toast} from "vant";
const pageData = ref([])

onMounted(() => {
  mitts.emit("toptitle", true);
  API({
    url: "/api/incomeNav",
    method: "post",
    data: {},
  }).then((res) => {
    if (res.data.status == 200) {
      pageData.value = res.data.data
      //总线传参赋值顶部菜单
      mitts.emit("secondMenus",   pageData.value.top_nav)
      mitts.emit("auths", pageData.value.top_nav_auth)
      //总线传参赋值底部菜单
      mitts.emit('navs', pageData.value.main_nav);
      mitts.emit('navindex', 'statistics');
    } else {
      Toast.fail(res.data.msg);
    }
  });

  setTimeout(() => {
    mitts.emit("closeLoading");
  }, 500)
})
</script>

<style lang="less" scoped>
.billingList {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
}

.page_cont {
    flex-grow: 1;
    height: 10px;
    overflow-y: auto;
    position: relative;
    margin-top: -210px;
}

.topimg {
    width: 100%;
    img {
      width: 100%;
      display: block;
    }
  }
</style>